import { Injectable } from '@angular/core';
import { MAP_STAMP_LOGO } from '@constants/app.constants';
import { BackgroundImage, Filter, FilterOptions, PostcardData, PostcardJsonData } from '@models/app.models';
import { fabric } from 'fabric';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostcardService {

  img = new Image();

  postcardData = new BehaviorSubject<PostcardData>({
    frontCanvas: this.img,
    backCanvas: this.img,
    postcard: this.img
  });
  postcardJSONData: PostcardJsonData;
  filterOptions: Filter[];
  filterOption: FilterOptions = 'background';
  showSidebar = new Subject<boolean>();
  defaultBackgroundUrl = new Subject<BackgroundImage>();
  defaultBackgroundImage: fabric.Image;

  constructor() { }

  dataURLtoFile(dataUrl = '', format = 'jpeg'): File {
    const arr = dataUrl.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `Map-postcard.${format}`, { type: 'image/jpeg' });
  }

  setMapLogoToFront(mapLogo: fabric.Image, canvas: fabric.Canvas) {
    canvas.bringToFront(mapLogo);
  }

  addMapLogo(mapLogo: fabric.Image, canvas: fabric.Canvas) {
    const { width } = canvas;
    const img = new Image();
    img.src = MAP_STAMP_LOGO;
    img.onload = () => {
      mapLogo = new fabric.Image(img, {
        selectable: false,
        width: 100,
        height: 100
      });
      mapLogo.set({
        top: 20,
        left: width! - 20 - (img.naturalWidth * mapLogo.scaleX!)
      });
      canvas.add(mapLogo);
      canvas.renderAll();
    };
  }
}
